import { acceptHMRUpdate, defineStore } from 'pinia'
import jsrsasign from 'jsrsasign'
import config from '@/config'
import cookies from '@/utils/cookies'
import { enumList, shopDataInfo, publicKey } from '@/api/system'
import { userView as getUserViewApi } from '@/api/user'
import { getSession, setSession } from '@/utils/storage'
import cookie from '@/utils/cookies'

const tokenName = config.tokenName
export const useUserStore = defineStore('user', () => {
  /**
   * Current named of the user.
   */
  const user = reactive({
    publicKey: '',
    view: {},
    token: getToken() || ''
  })
  const savedName = ref('')
  const previousNames = ref(new Set())
  const token = computed(() => user.token)
  const usedNames = computed(() => Array.from(previousNames.value))
  const otherNames = computed(() =>
    usedNames.value.filter((name) => name !== savedName.value)
  )
  const userView = computed(() => user.view)
  const shopData = ref(null)
  const domain = ref('')
  const apply = ref('')
  const promoterConfig = computed(() => domain.value)
  const meetCondition = computed(() => apply.value)

  const setUserView = (view) => {
    user.view = view
  }
  const setUserToken = (token) => {
    user.token = token
  }
  const getPromoterConfigs = (config) => {
    domain.value = config
  }
  const getPromoterApply = (config) => {
    apply.value = config
  }

  /**
   * Changes the current name of the user and saves the one that was used
   * before.
   *
   * @param name - new name to set
   */
  function setNewName (name) {
    if (savedName.value) previousNames.value.add(savedName.value)

    savedName.value = name
  }
  function setToken (key) {
    setUserToken(key)
    const NowTime = new Date()
    NowTime.setTime((NowTime.getTime()) + config.tokenExpires)
    cookies.setInClient({ key: tokenName, val: key, expires: NowTime })
  }

  function getToken () {
    let cookieData = {}
    if (process.server) {
      const nuxtApp = useNuxtApp()
      if (nuxtApp && nuxtApp.ssrContext && nuxtApp.ssrContext.event && nuxtApp.ssrContext.event.req) {
        cookieData = cookie.getInServer(nuxtApp.ssrContext.event.req)
      }
      return cookieData[tokenName] || ''
    } else {
      return cookies.getInClient(tokenName)
    }
  }

  function removeToken () {
    setUserToken('')
    cookies.delInClient(tokenName)
  }

  function getLocalSession (key) {
    const obj = getSession(key)
    if (obj) {
      return JSON.parse(obj)
    } else {
      return null
    }
  }
  function toObj (arr = []) {
    const obj = {}
    for (let i = 0; i < arr.length; i++) {
      obj[arr[i].code] = arr[i].desc
    }
    return obj
  }

  function getEnumList (enumName, type = 'arr') {
    return new Promise((resolve, reject) => {
      if (process.client) {
        const sessData = getSession(enumName)
        if (sessData) {
          if (type === 'obj') {
            resolve(toObj(JSON.parse(sessData)))
          } else {
            resolve(JSON.parse(sessData))
          }
          return
        }
      }

      enumList(enumName).then((response) => {
        if (Response) {
          let resData = response
          if (process.client) {
            setSession(enumName, response)
          }
          if (type === 'obj') {
            resData = toObj(response)
          }
          resolve(resData)
        } else {
          reject(response.msg)
        }
      }).catch(error => {
        reject(error)
      })
    })
  }
  function getShop () {
    return shopData.value
  }
  function getShopInfo () {
    return new Promise((resolve, reject) => {
      if (shopData && shopData.value) {
        resolve(shopData.value)
      } else {
        shopDataInfo({}).then((res) => {
          shopData.value = res
          console.log('getShopInfo' + JSON.stringify(shopData.value))
          resolve(res)
        }).catch((err) => {
          reject(err)
        })
      }
    })
  }

  function clearUserView () {
    setSession('userInfo', '')
  }

  function getUserView (isclear = false) {
    return new Promise((resolve, reject) => {
      let sessData = false
      if (process.client) {
        if (!isclear) { // 如果要刷新用户信息就不要读取缓存了 从服务器读取
          sessData = getSession('userInfo')
        }
      }
      if (sessData) {
        const user = JSON.parse(sessData)
        setUserView(user)
        resolve(user)
      } else {
        if (user.token) {
          getUserViewApi({}).then((response) => {
            if (response) {
              const resData = response
              setUserView(response)
              if (process.client) {
                setSession('userInfo', response)
              }
              resolve(resData)
            } else {
              reject(response.msg)
            }
          })
        } else {
          resolve('')
        }
      }
    })
  }

  function getPublicKey () {
    return new Promise((resolve, reject) => {
      publicKey().then(res => {
        user.publicKey = res
        resolve()
      })
    })
  }
  function getRsaCode (word) {
    const publicKey = `-----BEGIN PUBLIC KEY-----
      ${user.publicKey}
-----END PUBLIC KEY-----`
    console.log(publicKey)
    const key = jsrsasign.KEYUTIL.getKey(publicKey)
    const sigVal = key.encrypt(word)
    return sigVal
  }

  return {
    setNewName,
    otherNames,
    savedName,
    userView,
    token,
    shopData,
    promoterConfig,
    meetCondition,
    getLocalSession,
    getToken,
    getEnumList,
    getUserView,
    clearUserView,
    getShopInfo,
    getShop,
    setToken,
    setUserView,
    removeToken,
    getPromoterConfigs,
    getPromoterApply,
    getPublicKey,
    getRsaCode
  }
})

if (import.meta.hot) { import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot)) }
